import { IShellEvent } from './IShellEvent';
import { ShellCommunicationEvents } from '@shared/generalInterfaces';
import { Injectable } from '@angular/core';
import { ApplicationNavigationService } from 'app/services/shell/application-navigation/application-navigation.service';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class CloseMeEvent implements IShellEvent {

	constructor(private applicationNavigationService: ApplicationNavigationService,
		private router: Router) { }

	get eventName(): string {
		return ShellCommunicationEvents.CloseMeEvent;
	}

	async handleCustomEvent(event: any) {
        let transition = this.applicationNavigationService.popNavigationTransition();
        if (transition) {
            this.applicationNavigationService.stopClosingAppTimer(transition.transitionId);
			const url = new URL(transition.urlFrom);
            this.router.navigateByUrl(url.pathname);
        } else {
            this.applicationNavigationService.navigateToHomePageWhenTransitionNotFound(event.eventHeader.publisher);
        }
	}

	handlePostMessage(event): void {}
}
