import { Injectable } from '@angular/core';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { Observable, of, interval, BehaviorSubject, Subscription } from 'rxjs';
import { 
	FileInfo, 
	FileInfoStatus,
	DownloadFileInfo, 
	CreateOrthodonticsExportFileRequestDto, 
	CreateRestorativeExportFileRequestDto, 
	CreateExportFilesRequestDto, 
	ExportInfoDto
} from './fileInfo.model';
import { NanobarService } from '../../core/nanobar.service';
import { RxService } from '../../rx/services/rx.service';
import { GlobalSettingsService } from '../../core/globalSettings.service';
import { FeatureToggle, RoleTypeEnum } from '../../shared/enums';
import * as AT from '../../shared/actionTypes';
import { EupObserver } from '../../core/eupObserver.service';
import { map, flatMap, tap, takeUntil, mergeMap } from 'rxjs/operators';
import { BaseDestroyable } from '@core/base-destroyable';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';
import { FeatureToggleSettings } from 'app/featuresToggleSettings/featuresToggleSettings.model';
import { TimberService } from '@logging/timber.service';
var hash = require('object-hash');

@Injectable()
export class DownloadNotificationService extends BaseDestroyable {
	private observable1$: BehaviorSubject<FileInfo[]> = new BehaviorSubject<FileInfo[]>([]);
	private exportFileRequestsSubscription: Subscription;
	private files: FileInfo[] = [];
	private interval: number = 5 * 1000;

	constructor(
		private http: EupHttpHandler,
		private eupRoutesService: EupRoutesService,
		private nanobarService: NanobarService,
		private rxService: RxService,
		private globalSettings: GlobalSettingsService,
		private eupObserver: EupObserver,
		private featuresToggleSettingsService: FeaturesToggleSettingsService
	) {
		super();
	}

	initialize(){
		this.observable1$= new BehaviorSubject<FileInfo[]>([]);
		if (this.exportFileRequestsSubscription === undefined) {
			this.exportFileRequestsSubscription = this.featuresToggleSettingsService.getAll().pipe(
				takeUntil(this.componentAlive$),
				map((res: FeatureToggleSettings[]) => res.find(f => f.id === FeatureToggle.SubscribeExportFilesOnlyOnce)?.isActive === true),
				mergeMap(subscribeExportFilesOnlyOnce => {
					if(subscribeExportFilesOnlyOnce) {
						return this.handleExportFileRequests();
					}
					return of();
				})).subscribe();
		}		
	}

	getObservable(): BehaviorSubject<FileInfo[]> {
		if (this.observable1$ && this.observable1$.value && this.observable1$.value.length === 0) {
			this.observable1$.next(this.files);
			this.exportFileRequestsSubscription = this.handleExportFileRequests().subscribe();
		}

		return this.observable1$;
	}

	getFiles(): BehaviorSubject<FileInfo[]> {
		if (this.observable1$ && this.observable1$.value && this.observable1$.value.length === 0) {
			this.observable1$.next(this.files);
		}

		return this.observable1$;
	}

	private handleExportFileRequests(): Observable<FileInfo[]> {
		return interval(this.interval)
			.pipe(
				flatMap(() => {
					var exportFileRequests = this.getExportFileRequests();

					if (exportFileRequests === null) {
						return of([]);
					}

					return this.http.post(
						this.eupRoutesService.orders.getExportStatusBulk,
						exportFileRequests,
						undefined,
						false,
						false
					);
				}),
				map((files: FileInfo[]) => {
					files.forEach(file => file.requestData = this.getRequest(file.requestData));
					const result = files.reduce((acc, file) => ({ ...acc, [hash(file.requestData)]: file }), {});

					// update the files
					this.files.forEach((file, index, array) => {
						const id = hash(file.requestData);
						array[index] = { ...array[index], ...result[id] };
					});

					// remove only one file which is ready in givin time
					const idx = this.files.findIndex(
						(file) => file.status === FileInfoStatus.Completed && file.downloaded === false
					);
					if (idx > -1) {
						this.downloadFile(this.files[idx]);
						this.files.splice(idx, 1);
					}

					return this.files;
				})
			);
	}

	private getRequest(request: CreateOrthodonticsExportFileRequestDto | CreateRestorativeExportFileRequestDto) {
		if ((request as CreateRestorativeExportFileRequestDto).cadCamSystemTypeId != undefined) {
			var restoReq = new CreateRestorativeExportFileRequestDto();
			restoReq.cadCamSystemTypeId = (request as CreateRestorativeExportFileRequestDto).cadCamSystemTypeId;
			restoReq.clientId = request.clientId;
			restoReq.creationTime = request.creationTime;
			restoReq.orderDetailsId = request.orderDetailsId;
			restoReq.orderId = request.orderId;
			restoReq.patientAnonymization = request.patientAnonymization;
			return restoReq;
		} else {
			var orthoReq = new CreateOrthodonticsExportFileRequestDto();
			orthoReq.fileFormat = (request as CreateOrthodonticsExportFileRequestDto).fileFormat;
			orthoReq.fileType = (request as CreateOrthodonticsExportFileRequestDto).fileType;
			orthoReq.typeFormat = (request as CreateOrthodonticsExportFileRequestDto).typeFormat;
			orthoReq.clientId = request.clientId;
			orthoReq.creationTime = request.creationTime;
			orthoReq.orderDetailsId = request.orderDetailsId;
			orthoReq.orderId = request.orderId;
			orthoReq.patientAnonymization = request.patientAnonymization;
			return orthoReq;
		}
	}

	private getExportFileRequests(): CreateExportFilesRequestDto {
		const activeRequests = this.files.filter(
			(file) => file.status !== FileInfoStatus.Failed && file.status !== FileInfoStatus.Canceled
		).map((file) => file.requestData);

		const exportReq: CreateExportFilesRequestDto = {};
		var orthoRequests = activeRequests.filter((file) => file instanceof CreateOrthodonticsExportFileRequestDto)
			.map((file) => file as CreateOrthodonticsExportFileRequestDto);
		var restoRequests = activeRequests.filter((file) => file instanceof CreateRestorativeExportFileRequestDto)
			.map((file) => file as CreateRestorativeExportFileRequestDto);
		
		if(orthoRequests.length === 0 && restoRequests.length === 0) {
			return null;
		}

		if (orthoRequests.length > 0) {
			exportReq.orthodonticsExportRequest = { dtos: orthoRequests };
		}

		if (restoRequests.length > 0) {
			exportReq.restorativeExportRequest = { dtos: restoRequests };
		}
		
		return exportReq;
	}

	downloadFile(file: DownloadFileInfo): void {
		this.nanobarService.start();
		var url = this.getDownloadUrl(file);
		this.downloadFileFromUrl(url, file.orderId, file.requestData);
		this.nanobarService.stop();

		const settings = this.globalSettings.get();
		if (settings.roleType === RoleTypeEnum.Lab) {
			this.rxService.setReadyForDownloadAsDownloadedForLab(file.orderId).subscribe(() => {
				this.eupObserver.emit({
					action: AT.DOWNLOAD_NOTIFICATION_SERVICE_DOWNLOADED,
					payload: null
				});
			});
		}
	}

	private getDownloadUrl(file: DownloadFileInfo): string {
		const companyId = this.globalSettings.get().selectedCompanyId;
		const url =
			file.workOrderId && file.workOrderId > 0
				? this.eupRoutesService.orders.getIdeFileByWorkOrder
				: this.eupRoutesService.orders.getIdeFile;
		file.downloaded = true;
		var queryParams =
			file.workOrderId && file.workOrderId > 0
				? `${url}?workOrderId=${file.workOrderId}&orderId=${file.orderId}`
				: `${url}?orderId=${file.orderId}`;

				if (file.requestData instanceof CreateOrthodonticsExportFileRequestDto) {
					let requestData = file.requestData as CreateOrthodonticsExportFileRequestDto;
					queryParams = `${queryParams}&patientAnonymization=${requestData.patientAnonymization}&cadCamSystemType=${-1}&exportTypeForiRecord=${requestData.typeFormat}&dataFormat=${requestData.fileFormat}&fileType=${requestData.fileType}`;
				} else {
					let requestData = file.requestData as CreateRestorativeExportFileRequestDto;
					queryParams = `${queryParams}&patientAnonymization=${requestData.patientAnonymization}&cadCamSystemType=${requestData.cadCamSystemTypeId}&exportTypeForiRecord=${-1}&dataFormat=${-1}&fileType=${-1}`;
				}

			    if(companyId) queryParams = `${queryParams}&companyid=${companyId}`;

		return queryParams;
	}

	private downloadFileFromUrl(url: string, orderId: number, requestData: CreateOrthodonticsExportFileRequestDto | CreateRestorativeExportFileRequestDto) {
		this.featuresToggleSettingsService.getAll().pipe(
			takeUntil(this.componentAlive$),
			tap((res: FeatureToggleSettings[]) => {
				const downloadViaLink = res.find(f => f.id === FeatureToggle.DownloadViaLink)?.isActive === true;
				if(downloadViaLink) {
					var isOrtho = requestData instanceof CreateOrthodonticsExportFileRequestDto;
					this.downloadViaLink(url, orderId, isOrtho);
				} else {
					this.downloadViaIfram(url);
				}
			})).subscribe();
	}

	downloadViaIfram(url: string) {
		const iframe = document.createElement('iframe');
		iframe.src = url;
		iframe.style.display = 'none';
		iframe.tabIndex = -1;
		document.body.appendChild(iframe);
		setTimeout(() => { document.body.removeChild(iframe); }, 100000);
	}

	downloadViaLink(url: string, orderId: number, isOrtho: boolean) {
		this.http.get(url, {responseType: 'blob', observe: 'response'}, false, true)
		.subscribe(res => {
			var data_url = URL.createObjectURL(res.body);
			var fileName = '';
			try {
				fileName = res.headers?.get('content-disposition')?.split('filename=')[1].split(';')[0];
			} catch {
				fileName = `${isOrtho ? 'OrthoCAD_Export_' : 'iTero_Export_'}${orderId}.zip`;
			}
			var anchor = document.createElement("a");
			anchor.style.display = 'none';
			anchor.tabIndex = -1;
			anchor.download = fileName;
			anchor.href = data_url;
			document.body.appendChild(anchor);
			anchor.click();
			setTimeout(() => { document.body.removeChild(anchor); }, 100000);
		});
	}

	addFile(order: CreateOrthodonticsExportFileRequestDto | CreateRestorativeExportFileRequestDto) {
		const req = {
			orderId: order.orderId,
			orderDetailsId: order.orderDetailsId,
			progress: 0,
			downloaded: false,
			requestData: order,
			status: FileInfoStatus.Active
		} as FileInfo;

		var exportSettingsHash = hash(order);
		const idx = this.files.findIndex((file) => hash(file.requestData) === exportSettingsHash);
		if (idx === -1) {
			this.files.push(req);
		}
	}

	removeFile(fileToRemove: FileInfo) {
		const index = this.files
			.findIndex((file) => hash(file.requestData) === hash(fileToRemove.requestData));
		this.files.splice(index, 1);
	}

	clear(): void {
		this.observable1$ = undefined;
		this.exportFileRequestsSubscription?.unsubscribe();
		this.exportFileRequestsSubscription = undefined;
		this.files.splice(0, this.files.length);
	}

	getExportInfoBulkByWorkOrder(requestData: CreateExportFilesRequestDto): Observable<ExportInfoDto[]> {
		return this.http.post(this.eupRoutesService.orders.getExportInfoBulkByWorkOrder, requestData);
	}

	getOrthodonticsExportInfoByWorkOrder(requestData: CreateOrthodonticsExportFileRequestDto): Observable<ExportInfoDto> {
		return this.http.post(this.eupRoutesService.orders.getOrthodonticsExportInfoByWorkOrder, requestData);
	}
	
	getRestorativeExportInfoByWorkOrder(requestData: CreateRestorativeExportFileRequestDto): Observable<ExportInfoDto> {
		return this.http.post(this.eupRoutesService.orders.getRestorativeExportInfoByWorkOrder, requestData);
	}

	getOrthodonticsExportInfo(requestData: CreateOrthodonticsExportFileRequestDto): Observable<ExportInfoDto> {
		return this.http.post(this.eupRoutesService.orders.getOrthodonticsExportInfo, requestData);
	}
	
	getRestorativeExportInfo(requestData: CreateRestorativeExportFileRequestDto): Observable<ExportInfoDto> {
		return this.http.post(this.eupRoutesService.orders.getRestorativeExportInfo, requestData);
	}
}
