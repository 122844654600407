<div [ngClass]="{'content': isLongTitle}">
    <header [ngClass]="{grouped: isVerticalMenu}">
        <div class="image-link-container">
            <a id="link-back" class="image-link" ngClass="{{ 'svg-' + image + ' svg-' + image + '-dims' }}"
                (click)="onImageLinkClick()"></a>
        </div>
        <img *ngIf="titleLogo" src="{{titleLogo}}" class="header-title-logo" alt=""/>
        <span *ngIf="dynamicTitleText" class="header-title">
            {{ dynamicTitleText }}
        </span>
        <div class="contact-selection contact-selection-container" *ngIf="!hideContactSelection">
            <div class="grid-container">
                <div class="download-component-container"></div>

                <div class="dropdowns-container">
                    <a class="svg-company28 svg-company28-dims contact-logo"></a>
                    <div class="eup-dropdown" (clickOutside)="hideCompaniesMenu()">
                        <div dropdown [isOpen]="isCompaniesMenuOpen" (isOpenChange)="companiesMenuToggled($event)"
                            class="dropdown-container" [ngClass]="{ disabled: isCompanyDropdownDisabled }">
                            <a id="companyDropdown" dropdownToggle title="{{ selectedCompany.name }}">{{
                                selectedCompany.name }} <span class="caret"
                                    *ngIf="!isCompanyDropdownDisabled"></span></a>
                            <ul id="companyDropdownUl" class="dropdown-menu" *dropdownMenu>
                                <input id="txtSearchCompany" *ngIf="showCompaniesFiltering" type="text"
                                    placeholder="Search" class="search-company" (click)="searchClicked($event)"
                                    [(ngModel)]="searchCompanyText" (input)="onSearchCompany($event)"
                                    autocomplete="off">
                                <li *ngFor="let company of filteredCompanies" (click)="onCompanyChanged(company)">
                                    {{ company.name }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="dropdowns-separator"></div>

                    <a class="svg-doctor28 svg-doctor28-dims contact-logo"></a>
                    <div class="eup-dropdown" (clickOutside)="hideDoctorMenu()">
                        <div dropdown [isOpen]="isDoctorsMenuOpen" (isOpenChange)="doctorsMenuToggled($event)"
                            class="dropdown-container" [ngClass]="{ disabled: isDoctorDropdownDisabled }">
                            <a id="contactDropdown" dropdownToggle title="{{ selectedDoctor.contactName }}">{{
                                selectedDoctor.contactName }}<span class="caret"
                                    *ngIf="!isDoctorDropdownDisabled"></span></a>
                            <ul id="contactDropdownUl" class="dropdown-menu" *dropdownMenu>
                                <li *ngFor="let doctor of doctors" (click)="onDoctorChanged(doctor)">
                                    {{ doctor.contactName }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="download-component-container">
                    <eup-download-notification (open)="hideDoctorAndCompaniesMenu()"></eup-download-notification>
                </div>
            </div>
        </div>

        <div [ngClass]="{'outside-content': hideContactSelection}">
            <ng-content></ng-content>
        </div>

        <div *ngIf="insideContent" class="align-right">
            <div *ngIf="exportVerticalMenu" class="download-component-container">
                <eup-download-notification></eup-download-notification>
            </div>
            <button *ngIf="downloadScreenshots"
                id="downloadScreenshot"
                class="btn btn-primary download-screenshot-btn"
                [ngClass]="{ disabled: !order?.isCaptureFileExist }"
                [disabled]="!order?.isCaptureFileExist"
                (click)="onDownloadScreenshotClick()"
            >
                <svg class="svg-downloadScreenshots34 svg-downloadScreenshots34-dims"></svg>
            </button>
            <div *ngIf="screenshots"
                id="takeScreenshot"
                class="capture-btn"
                (click)="takeScreenshot()"
                [disabled]="shouldDisableCaptureTool"
                [ngClass]="{ disabled: shouldDisableCaptureTool }"
            >
                <svg class="svg-capture46 svg-capture46-dims"></svg>
            </div>
        </div>

        <div class="avatar-container" (clickOutside)="hideLogoutMenu()">
            <div class="image-link-container">
                <a id="logoutImage" class="image-link svg-avatar47 svg-avatar47-dims clickable"
                    (click)="toggleLogoutMenu()"></a>
            </div>
            <div class="avatar-menu" [ngClass]="{ open: isLogoutOpen }">
                <p>{{ username }}</p>
                <button id="logoutButton" class="btn btn-primary" (click)="logout()">
                    {{ 'StickyHeader.Logout' | translate }}
                </button>
            </div>
            <span>{{ 'StickyHeader.Logout' | translate }}</span>
        </div>

        <div class="links-container">
            <div class="vertical-menu" (click)="toggleLinksMenu()" (clickOutside)="hideLinksMenu()" [ngClass]="{open: isVerticalMenu}">
                <div class="clickable">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                </div>
                <ul class="responsive-menu" [ngClass]="{
                    open: isLinksMenuOpen,
                    disabled: disableSiteNavigationFunc
                }">
                    <li *ngIf="isVerticalMenu && exportVerticalMenu" (click)="onExportClick($event)">
                        <div class="icon-container">
                            <svg class="svg-exportSettings34 svg-exportSettings34-dims"></svg>
                        </div>
                        <span>{{ 'Rx.Export' | translate }}</span>
                    </li>
                    <li *ngIf="enableAnalytics" >
                        <a id="analytics-icon-vertical-menu" (click)="clickAnalytics()">
                            <div class="icon-container">
                                <div class="image-link analytics-icon clickable"></div>
                            </div>
                            <span>{{ 'iTeroAnalytics.iTeroAnalyticsTitle' | translate }}</span></a></li>
                    <li>
                        <a id="learningLink"
                            [routerLink]="['/' + globalSettingsService.rolePath() + '/learningcenter']">
                            <div class="icon-container">
                                <div class="image-link svg-education52 svg-education52-dims education-logo"></div>
                            </div>
                            <span>{{ 'StickyHeader.Learning' | translate }}</span>
                        </a>
                    </li>
                    <li>
                        <a id="settingsLink" (click)="settingsModal.show()">
                            <div class="icon-container">
                                <div class="image-link svg-settings45 svg-settings45-dims"></div>
                            </div>
                            <span>{{ 'StickyHeader.GeneralSettings' | translate }}</span>
                        </a>
                    </li>
                    <li *ngIf="enablePracticeManagement">
                        <a id="practiceSettingsLink" (click)="onPracticeManagementLinkClick()">
                            <div class="icon-container">
                                <div class="image-link svg-practiceSettings45 svg-practiceSettings45-dims"></div>
                            </div>
                            <span>{{ 'StickyHeader.PracticeSettings' | translate }}</span>
                        </a>
                    </li>
                </ul>
            </div>

            <ul class="horizonal-menu" *ngIf="!isVerticalMenu">
                <li *ngIf="enableAnalytics">
                    <a id="analytics-icon-horizontal-menu" class="image-link analytics-icon clickable" (click)="clickAnalytics()"></a></li>
                <li>
                    <a id="learningLink" class="image-link-container"
                        [routerLink]="['/' + globalSettingsService.rolePath() + '/learningcenter']">
                        <div class="image-link svg-education52 svg-education52-dims education-logo"></div>
                        <span>{{ 'StickyHeader.Learning' | translate }}</span>
                    </a>
                </li>
                <li class="settings-link">
                    <!-- PracticeManagement feature toggle off -->
                    <a *ngIf="!enablePracticeManagement" id="horizontal-settingsLink1" class="image-link-container"
                        (click)="settingsModal.show()">
                        <div class="image-link svg-settings45 svg-settings45-dims"></div>
                        <span>{{ 'StickyHeader.Settings' | translate }}</span>
                    </a>
                    <!-- account management feature toggle on -->
                    <a *ngIf="enablePracticeManagement" id="horizontal-settingsLink2" class="image-link-container"
                        (click)="toggleSettingsMenu()" (clickOutside)="hideSettingsMenu()">
                        <div class="image-link svg-settings45 svg-settings45-dims"></div>
                        <span [ngClass]="{ hidden: isSettingsMenuOpen }">{{ 'StickyHeader.Settings' | translate
                            }}</span>
                    </a>
                    <div class="settings-menu" [ngClass]="{ open: isSettingsMenuOpen }">
                        <ul>
                            <li (click)="settingsModal.show()">
                                <div class="icon-container">
                                    <div class="image-link svg-settings45 svg-settings45-dims"></div>
                                </div>
                                <div class="menu-content">{{ 'StickyHeader.GeneralSettings' | translate }}</div>
                            </li>
                            <li (click)="onPracticeManagementLinkClick()" id="practiceManagementTab">
                                <div class="icon-container">
                                    <div class="image-link svg-practiceSettings45 svg-practiceSettings45-dims"></div>
                                </div>
                                <div class="menu-content">
                                    {{ 'StickyHeader.PracticeSettings' | translate }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </header>
</div>
<eup-settings id="modal-settings"></eup-settings>
<eup-orthoCadExport *ngIf="exportVerticalMenu" id="modal-ortho-export"></eup-orthoCadExport>
<eup-restorativeExport *ngIf="exportVerticalMenu" id="modal-restorativeExport"></eup-restorativeExport>
<ada-chat-bot *ngIf="isChatBotVisible" [routeHasLogin]="routeHasLogin"></ada-chat-bot>